import React, { useEffect, useState } from 'react';

import { get_researcher, get_news, get_paper } from '../api'
import { MdContent, LoadingCircle } from '../components'
import { nice_date } from '../utils'
import { news_kind_to_icon as nkti } from '../globals'
import { News } from '../api/auto-types';
import { PaperView } from './Publications';


const Home = () => {
  const [newss, setNews] = useState([] as News[]);
  const [papers, setPapers] = useState([]);
  const [researchers, setResearchers] = useState([]);

  useEffect(() => {
    get_news().then(news => setNews(news.slice(0, 8)))
    get_researcher().then(setResearchers)
    get_paper({}).then(setPapers)
  }, [])


  const horiz = (window.innerWidth > window.innerHeight);
  const logo_size = horiz ? Math.floor(window.innerWidth / 3) : Math.floor(3 * window.innerWidth / 4);

  return (
    <div className="main-container mb-5">
      <div style={{ width: '100%' }}>

        <div id="homepage-image-first">
          <div style={{ zIndex: 10 }} className="container">
            <img width={`${logo_size}px`} className="mb-3" src="/static/qinfosns/images/qinfo_logo.png" />
            <p className="px-2 text-center">
              Welcome to the home page of the Quantum Information Theory group @ Scuola Normale Superiore
            </p>
          </div>
        </div>

        <div id="homepage-section-news">
          <div id="homepage-section-news-content" className="py-4">
            <h1 className="simil-header">News</h1>
            <div className="container">
              <ul style={{ paddingInlineStart: '0px' }}>
                {newss.map(news => {
                  if (news.kind == "ppp") {
                    const paper = papers.find(p => p.id == news.related_paper)
                    if (!paper) {
                      return <LoadingCircle key={news.id} />
                    }
                    return <PaperView key={news.id} paper={paper} researchers={researchers} start_open={false} />

                  }
                  const news_icon = nkti(news.kind);
                  return (
                    <div className="card my-2" key={news.id}>
                      <div className="card-header d-flex justify-content-between">
                        <span><i className={`fa fa-${news_icon} mr-3`} />{news.title}</span>
                        <span>{nice_date(news.date)}</span>
                      </div>
                      <div className="card-body">
                        <MdContent source={news.description} />
                      </div>
                    </div>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}


export default Home
