import { WithId } from '../django-rest-react/low_level';


declare var __SITE_URL__: string;
declare var __DEBUG__: boolean;
declare var __SENTRY_USE_SENTRY__: boolean;
declare var __SENTRY_DSN__: string;
declare var __VERSION__: string;
declare var __THE_SENDING_MAIL__: string;
declare var __JEST_TESTING__: boolean;

export const site_url: string = __SITE_URL__;
export const DEBUG = __DEBUG__;
export const SHOW_STORE_DEBUG = __JEST_TESTING__ ? false : DEBUG;
export const VERSION = __VERSION__;
export const PROJECT_NAME = "qinfosns";
export const THE_SENDING_MAIL = __THE_SENDING_MAIL__;
export const TESTING = __JEST_TESTING__;

export const sentry_config = {
  use_sentry: __SENTRY_USE_SENTRY__,
  dsn: __SENTRY_DSN__,
  release: VERSION,
}

export { default_date_options as date_options, DEFAULT_LOCALE } from 'django-rest-react'


export const toast_style = {
  position: 'absolute',
  top: 0,
  right: 0,
}


export * from './display'


export const image_placeholder_url = `/static/${PROJECT_NAME}/images/profile_placeholder.png`
